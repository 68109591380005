import {addDays, startOfDay} from "date-fns"

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
    typeof window !== 'undefined'
        ? /constructor/i.test(window.HTMLElement) ||
          ((p) => p.toString() === '[object SafariRemoteNotification]')(
              !window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)
          )
        : false

// Internet Explorer 6-11
export const isIE = typeof window !== 'undefined' ? /*@cc_on!@*/ false || !!document.documentMode : false

export const isEven = (num) => num % 2 == 0

export const isOdd = (num) => Math.abs(num % 2) == 1

export const moneyFormatter = (num) =>
    new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(num)

export const TODAY = startOfDay(new Date())

export const TOMORROW = addDays(TODAY, 1)

export const getDatesBetween = (startDate, endDate) => {
    const from = new Date(startDate)
    const to = new Date(endDate)

    const dates = [new Date(from)]
    let tempDate = from

    while (tempDate < to)
    {
        tempDate.setDate(tempDate.getDate() + 1)
        dates.push(new Date(tempDate))
    }
    
    return dates
}

export const getDateYear = (date) => {
    const datetime = new Date(date)

    return datetime.getFullYear
}

export const currencyFormatter = (price, locale) => {
    let hufHungarian = Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0
    })

    let eurGerman = Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0
    })

    if (locale === 'eur')
    {
        return eurGerman.format(price)
    }

    return hufHungarian.format(price)
}

export const validateEmail = (email = null) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
        return true
    }

    return false
}