import axios from 'axios'

const apiClient = axios.create({
    headers: {
        'Content-tpye': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
})

export default apiClient