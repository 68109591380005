import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FormGroup, FormController, FormLabel, FormControlFeedback } from './formInputStyles'

const FormInput = ({ onChange, label, labelClass, groupClass, ...props }) => {
    return (
        <FormGroup className={groupClass}>
            <FormController onChange={onChange} {...props} />

            {label && (
                <FormLabel htmlFor={props.id} className={classnames(labelClass, { shrink: props.value.length })}>
                    {label}
                </FormLabel>
            )}

            <FormControlFeedback type="invalid">kötelező mező</FormControlFeedback>
        </FormGroup>
    )
}

FormInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    id: PropTypes.string,
    groupClass: PropTypes.string
}

FormInput.defaultProps = {
    type: 'text',
    value: '',
}

export default FormInput
