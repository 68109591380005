import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Form from 'react-bootstrap/Form'

const shrinkLabelStyles = css`
    top: -0.875rem; /* 14px */
    font-size: 0.75rem; /* 12px */
    color: var(--gray);
`

const inputStyle = css`
    background: none;
    background-color: white;
    color: var(--black);
    font-size: 1rem; /* 16px */
    padding: 0.625rem; /* 10px */
    padding-left: 0.375rem; /* 6px */
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 0.0625rem solid var(--gray);
    transition: 300ms ease all;
    text-transforms: none;
    font-family: sans-serif;

    &.is-invalid {
        border-color: var(--red);
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: var(--gold);
        color: var(--black);
    }

    .was-validated &:valid:focus,
    &.is-valid:focus,
    .was-validated &:invalid:focus,
    &.is-invalid:focus {
        box-shadow: none;
    }

    &:focus ~ label {
        ${shrinkLabelStyles}
    }
`

export const FormGroup = styled(Form.Group)`
    position: relative;
    margin-bottom: 2.5rem; /* 40px */
    position: relative;

    &.is-disabled {
        opacity: 0.65;
    }
`

export const FormController = styled(Form.Control)`
    ${inputStyle}
`

export const FormControllerTextarea = styled.textarea`
    ${inputStyle}
    resize: none;
`

export const FormLabel = styled(Form.Label)`
    &:not(.non-floating) {
        color: var(--black);
        font-size: 1rem; /* 16px */
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 0.375rem; /* 6px */
        top: 0.625rem; /* 10px */
        transition: 300ms ease all;
        margin: 0;
    }

    &.shrink {
        ${shrinkLabelStyles}
    }

    &.is-invalid {
        color: var(--red);
    }
`

export const FormControlFeedback = styled(Form.Control.Feedback)`
    position: absolute;
    top: calc(100% + 0.25rem);
    margin: 0;
`
